@import '@/styles/colors.module';

.container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, auto));

  .column {
    &:not(:last-child) {
      padding-right: 16px;
      margin-right: 16px;
      border-right: 1px solid $border_gray;
    }
  }
}
