@import '@/styles/colors.module';

.wrapper {
  margin-bottom: 16px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 32px;
}

.noData {
  margin-left: 32px;
}
