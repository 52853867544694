@import '@/styles/colors.module';

.wrapper {
  .title {
    min-width: 220px;
    font-size: 32px;
    line-height: 28px;
    font-weight: 600;
  }

  .input {
    width: 416px;
  }
}
