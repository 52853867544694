@import '@/styles/colors.module';

.title {
  column-gap: 16px;
  row-gap: 8px;

  .badge {
    color: $light_gray;
    background-color: $gray;
    border-radius: 20px;
    padding: 3px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    font-size: 14px;
    white-space: nowrap;

    &Orange {
      color: $orange;
    }
  }
}
