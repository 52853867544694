@import '@/styles/colors.module';
@import "@/styles/mixins";

.title {
  @include truncate;
  margin-bottom: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $light_gray;
}

.chipsList {
  column-gap: 4px;
  row-gap: 8px;

  .chips {
    @include truncate;
    background-color: $border_gray;
    padding: 4px;
    border-radius: 20px;
    font-size: 12px;
    text-align: right;
    font-weight: 400;
    height: 17px;
    display: flex;
    align-items: center;

    &.applicable {
      color: $light_blue;
      background-color: $light_blue_2;
      font-weight: 500;
    }

    &Text {
      height: 15px;
      line-height: 15px;
      font-size: inherit;
      color: inherit;
      font-weight: inherit;
      margin-bottom: 0;
    }
  }
}
