@import '@/styles/colors.module';

.card {
  padding: 24px 32px;
  border-radius: 8px;
  background-color: $white;

  &Name {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    b {
      background-color: $gold_2;
      font-weight: inherit;
    }
  }

  &CiName {
    color: $light_gray;
    font-size: 12px;
    font-weight: 500;
    margin-top: -7px;

    b {
      background-color: $gold_2;
      font-weight: inherit;
    }
  }

  &Link {
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $blue;
  }
}
