@import '@/styles/colors.module';

.label {
  font-size: 12px;
  color: $light_gray;
}

.value {
  font-size: 12px;
}

.button {
  width: fit-content;
  padding: 0 !important;
  border-bottom: 1px dashed $blue !important;
  border-radius: 0 !important;
  font-size: 12px !important;
}
