@import '@/styles/colors.module';

.label {
  font-size: 14px;
  font-weight: 500;
  color: $light_gray;
}

.link {
  font-size: 14px;
  font-weight: 500;
}
